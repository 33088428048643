<template>
    <div class="flex flex-col w-11/12 mx-auto justify-center mobileL:gap-1 mt-6 mb-4 text-black-1 font-poppins">
      <div class="w-auto relative">
          <img :src="service.image" :alt="service.name">
      </div>
      <div class="h-full w-full">
          <div class="flex justify-center font-bold h-auto text-xss mobileL:text-base lg:text-lg 2xl:text-xl w-full my-1">
              <div class="">
                  <h2 class="card-title truncate">{{ service.name }}</h2>
              </div>
          </div>
          <div class="flex justify-center items-center font-bold mobileL:mt-2">
              <h2 class="card-title text-xss mobileL:text-base lg:text-lg 2xl:text-xl bg-pink-17 px-5 text-white-1">
                {{ new Intl.NumberFormat('en-PH').format(service.srp) }}
            </h2>
          </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
      props: ['service'],

      watch: {
        service: {
            immediate: true,
            handler: function(service) {
                const image = service.image.split('/');
                const imgUrl =  image[image.length - 4] + "/" + image[image.length - 3] + "/" + image[image.length - 2] + "/" + image[image.length - 1];
                service.image = `${process.env.VUE_APP_IMAGE_BASE_URL}${imgUrl}`;
            }
        }
      }
  }
  </script>
  
  <style scoped>
  .hide-scroll::-webkit-scrollbar {
    width: 0 !important
  }

  </style>
