<template>
    <div>
      <div class="w-screen lavish-services-section-container lavish-services-banner relative space-top" v-if="hasNewSeasonalPage"></div>

      <div class="w-screen section-bg relative flex justify-start items-end xl:items-center space-top" v-else>
        <div class="absolute right-3 mb-4 md:mb-10 xl:mb-0 md:right-10 xl:right-24 2xl:right-32">
          <img class="w-80 md:w-106 xl:w-114 2xl:w-full" src="../../assets/images/services-page/generic-banners/lavish-text.png" alt="generic-lavish-description">
        </div>
        <img class="section-img -ml-24 md:-ml-28 xl:ml-40 " src="../../assets/images/services-page/generic-banners/lavish-model.png" alt="generic-lavish-model">
      </div>
  
      <div class="w-screen bg-white-1 lavish-gradient-bg py-7 mobileL:py-10 font-kannada text-black-1">
        <div class="relative w-8/12 mx-auto text-center grid justify-center">
          <h2 class="font-bold text-2xl mobileL:text-2xl lg:text-3xl xl:text-4xl 2xl:text-6xl text-center z-20">
            Eyelashes Extensions Prices & Services
          </h2>
          <span class="absolute bg-pink-4 w-full h-6 mobileL:h-8 lg:h-10 xl:h-12 2xl:h-16 bottom-3 xl:bottom-3 z-10"></span>
        </div>
  
        <div class="w-full mobileL:w-8/12 mx-auto mb-5 mobileL:mt-4 px-6 mobileL:px-0 ">
          <div class="grid grid-cols-3 w-full gap-4 mobileL:gap-2">
            <LavishServiceCard v-for="(service, index) in allServices" :key="index" :service="service" />
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapActions, mapGetters } from 'vuex';
  import LavishServiceCard from '../../components/LavishServiceCard.vue';
  export default {
    data() {
      return {
        allServices:[],
        windowWidth: window.innerWidth,
        hasNewSeasonalPage: false,
      }
    },
  
    components: {
        LavishServiceCard
    },
  
    methods: {
      ...mapActions('services', [
        'fetchServices',
        'fetchPackages',
        'fetchBusinessUnit',
      ]),

      handleResize() {
        this.windowWidth = window.innerWidth;
      },
    },

    mounted() {
      window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleResize);
    },
  
    computed: {
      ...mapGetters('services', ['allService', 'allPackage', 'allBusinessUnit', 'allFemaleService', 'allMaleService']),

      shouldShowElement() {
        return this.windowWidth > 480; 
      },

      shouldNotShowElement(){
        return this.windowWidth < 480; 
      },
    },
  
    created() {
      this.fetchServices({business_unit_id: this.$lavishLashesId});
    },

    watch: {
      allService: {
        immediate: true,
        handler: function(services) {
          if (!services.length) return;
          
          this.allServices = services;
        }
      }
    }
  }
  </script>
  
  <style scoped>
  .lavish-services-section-container {
      height: 700px;
  }

  .lavish-gradient-bg {
    background: radial-gradient(#ED7A97 -20%, #fef6ef 35%);
    border-radius: 10px;
  }
  
  .lavish-services-banner {
    background: url('../../assets/images/services-page/seasonal-banners/LL-June.jpg') no-repeat;
    background-size: 100% 100%;
  }
  
  .section-bg{
    background: url('../../assets/images/services-page/generic-banners/services-generic-bg.png') no-repeat;
    background-size: cover;
  }
  
  .space-top {
    margin-top: 90px;
  }

  .section-img{
    width:800px;
  }

  @media (max-width:480px) {
    .lavish-services-section-container {
      height:256px;
    }

    .lavish-gradient-bg {
      background: none;
    }

    .lavish-services-banner {
      background: url('../../assets/images/services-page/seasonal-banners/LL-Mobile-June.jpg') no-repeat;
      background-size: 100% 100%;
    }

    .section-img{
      width:750px;
    }

    .space-top {
      margin-top: 60px;
    }
  }

  @media (min-width: 1280px) {
    .space-top {
      margin-top: 100px;
    }

    .lavish-services-section-container {
      height: calc(100vh - 100px);
    }
}

@media (min-width: 1536px) {
    .space-top {
      margin-top: 128px;
    }

    .lavish-services-section-container {
      height: 110vh;
    }
}
  </style>